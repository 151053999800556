import React from "react"
import Success from "../components/success"

import sideImageL from "../images/siderailimg.png"
import sideImageR from "../images/siderailimg.png"
import "../components/static/styles/mainCheckout.css"

class Checkout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      prodCode: null,
    }
  }

  componentDidMount() {
    console.log(this.props.location.state)

    if (this.props.location.state.productCode) {
      const pCode = this.props.location.state.productCode
      this.setState({
        prodCode: pCode,
      })
    }
  }

  render() {
    return (
      <div className="mainCheckout">
        <img src={sideImageL} alt="sidebanner" className="sideImageLCheckout" />
        <div className="divStyles__checkout">
          <Success code={this.state.prodCode} quantity="2" />
        </div>
        <img src={sideImageR} alt="sidebanner" className="sideImageRCheckout" />
      </div>
    )
  }
}

export default Checkout
